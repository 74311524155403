<template>
  <div class="text-center">
    <img class="image d-none d-lg-block mx-auto" src="@/assets/img/prestamo-garantia-auto/persona-pensando.svg" alt="Persona pensando" />
  </div>
  <div class="p-lg-4 p-2 text-center background-orange-3 d-flex flex-column justify-content-center align-items-center">
    <div class="btn background-orange text-center d-flex align-items-center text-white regular-text mt-lg-5 mt-4 btn-solicitar ">
      <router-link  class="text-white" to="/#banner-form">
        Hacer solicitud
      </router-link>
    </div>
    <router-link to="#faq" class="mt-3 mb-2 text-blue bold-text link-offset-1 faqs">
      ¿Tienes dudas?
    </router-link>
  </div>
</template>

<script>
  export default {
    name: 'SolicitaloBanner',
    methods:{
      goTo(route, hash) {
            this.$router.push({ name: route, hash:hash  });
        },
    }
  }
</script>

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss'; 
  @import 'bootstrap/scss/_functions.scss';
  @import 'bootstrap/scss/_variables.scss';
  @import 'bootstrap/scss/_mixins.scss'; 


  .faqs {
    color: #004680;
      text-align: center;
      font-family: 'Montserrat-Bold';
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-decoration: underline;
  }
  .image{
    height: 450px;
  }

  .bold-text{
    font-family: 'Montserrat-Bold';
  }

  .background-orange-3{
    background-color: $orange-4;
  }

  .background-orange{
    background-color: $orange;
  }
  .text-blue{
    color: $blue;
  }

  .btn-solicitar{
    border-radius: 30px;
    padding: 10px 65px;;
    min-width: 250px;
    font-family: 'Montserrat-Bold';
  }

  @include media-breakpoint-up(lg){
    .image{
      height: 320px;
    }
  }

  @include media-breakpoint-down(md){

    .btn-solicitar{
      font-size: 16px!important;
      height: auto;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    
  .faqs {
    text-decoration: none;
  }
  }

  @include media-breakpoint-between(md, lg){

    .btn-solicitar{
      font-size: 18px!important;
      height: auto;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }
</style>