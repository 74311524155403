<template>
  <div class="section-container regular-text">
    <div class="position-relative pt-md-5 pb-3 pb-md-0 pt-5">  
      <div class="text-container">
        <p class="mb-3">
          A veces nos encontramos en situaciones en las que necesitamos dinero de forma rápida y urgente. Esta es una verdad tangible en México, donde 6 de cada 10 personas (61.4%) se han visto en la necesidad de solicitar un préstamo en los últimos 12 meses para financiar sus gastos, de acuerdo con datos de la Encuesta de Bienestar Económico y Actitudes hacia la Toma de Decisiones Financieras 2022.
        </p>

        <p class="mb-3">
          Por esto, conocer las diferentes opciones disponibles para obtener liquidez sin tener que desprendernos de nuestros bienes más preciados es una muy buena idea. Una de estas alternativas es el empeño de vehículos, una opción que permite obtener dinero rápido utilizando el auto como garantía.
        </p>

        <p class="mb-5 pb-md-3">
          Sin embargo, muchas personas no saben que existe la posibilidad de empeñar su auto sin tener que dejar de usarlo. Esta es la solución perfecta para aquellos que necesitan liquidez sin tener que renunciar a la comodidad y utilidad de su vehículo.
        </p>

        <h2 class="text-orange title bold-text mt-md-5 mt-3 mb-4">
          Beneficios de empeñar tu auto sin dejarlo
        </h2>

        <div class="mb-5 pb-md-3">
          <p class="mb-3">
            El <span class="bold-text">empeño de auto sin dejarlo</span> se ha convertido en una alternativa cada vez más popular para quienes buscan una solución financiera rápida. Estos son los beneficios más destacados de esta modalidad:
          </p>
  
          <p class="mb-3">
            <span class="text-orange">
              ✓
            </span>
            <span>Obtener dinero urgente</span><br />
            La principal ventaja de esta opción es que te permite obtener el dinero que necesitas de inmediato, sin tener que esperar a venderlo. Esto es especialmente útil en situaciones de emergencia o cuando se requiere una inyección de efectivo rápido.
          </p>
          <p class="mb-3">
            <span class="text-orange">
              ✓
            </span>
            <span>Conservar tu movilidad</span><br />
            A diferencia de empeñar tu vehículo y dejarlo en un establecimiento como garantía, esta opción te permite seguir usando tu auto en tu vida diaria. Esto significa que no tendrás que depender de otros medios de transporte y podrás cumplir con tus compromisos personales y laborales sin interrupciones.
          </p>
          <p class="mb-3">
            <span class="text-orange">
              ✓
            </span>
            <span>Mantener el valor del vehículo</span><br />
            A diferencia de vender tu auto, esta modalidad te permite conservar la propiedad del mismo. Esto es importante si valoras tu vehículo y no deseas perder su propiedad ni el valor de reventa a largo plazo.
          </p>
        </div>
        
        <h2 class="text-orange title bold-text mt-md-5 mt-3 mb-4">
          Proceso rápido y seguro de empeño
        </h2>

        <p class="mb-3">
          En momentos de apuros financieros, la rapidez y la seguridad son esenciales al considerar <span class="bold-text">empeñar tu auto sin dejarlo.</span> Con Ibancar, este es un proceso ágil y seguro. Estos son los pasos para el procedimiento: 
        </p>

        <p class="mb-3">
          <ol class="list-orange">
            <li>
              Ingresar a la web de Ibancar e indicar la información de tu auto y datos de contacto. Después de hacerlo, en cuestión de minutos, recibirás una propuesta de préstamo personalizada sin ningún compromiso.
            </li>
            <li>
              Envío de documentación y revisión inmediata. Una vez que aceptes la propuesta, el siguiente paso es proporcionar la documentación requerida. La buena noticia es que la revisión de la documentación se hace al instante. Así, agiliza el proceso para que no tengas que esperar más de lo necesario.
            </li>
            <li>
              Dinero en tu cuenta en menos de un día. Tras la verificación y aprobación de la documentación, el dinero ingresará en tu cuenta en máximo 48 horas. Esto significa que puedes resolver tus necesidades financieras de manera rápida y eficiente.
            </li>
          </ol>
        </p>
        <p>
          Gracias a la modalidad <span class="bold-text">"empeña tu auto sin dejarlo"</span>, el coche sigue siendo tuyo, y obtienes el dinero que necesitas. Así que, si buscas una solución rápida y confiable para tus necesidades financieras, Ibancar es la respuesta que estabas buscando.
        </p>
      </div>
    </div>
  </div>
  <!-- <SolicitaloBanner /> -->
  </template>
  
<script>

  export default {
    name: "EmpenaTuAutoContinuaUsandoloS1",
    components: {  }
  };
</script>
  
<style scoped lang="scss">
  @import '@/assets/styles/variables.scss'; 
  @import 'bootstrap/scss/_functions.scss';
  @import 'bootstrap/scss/_variables.scss';
  @import 'bootstrap/scss/_mixins.scss'; 
  
  .text-container{
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
  .regular-text{
    font-family: "Montserrat";
    font-size: 20px;
    line-height: 30px;
  }

  .title {
    font-size: 25px;
    line-height: 30px;
  }
  .bold-text{
    font-family: "Montserrat-Bold";
  }
  .text-orange{
    color: $orange;
  }
  .text-blue{
    color: $blue;
  }

  .text-black{
    color: $black;
  }

  .text-underline{
    text-decoration: underline;
  }

  .background-orange-3{
    background-color: $orange-4;
  }

  .btn-solicitar{
    a{
      &:hover{
        text-decoration: none;
      }
    }
  }

  .list-orange{
    li{
      &::marker{
        color: $orange;
        font-family: "Montserrat-Bold";
      }
    }
  } 

  
  @include media-breakpoint-down(md){
    .text-container{
      width: 90%;
    }

    .regular-text{
      line-height: 1.5;
    }

    ul{
      padding-inline-start: 20px;
    }

    ol{
      padding-inline-start: 20px;
    }
  }

  @include media-breakpoint-between(md, lg){
    .regular-text{
      font-size: 16px;
    }

    .title{
      font-size: 28px;
      line-height: 35px;
    }
    .subtitle{
      font-size: 16px;
    }
  }

  @include media-breakpoint-down(sm){
    .title{
      font-size: 20px;
      line-height: 25px;
    }
    .subtitle{
      font-size: 16px;
      line-height: 20px;
    }
    .regular-text{
      font-size: 16px!important;
    }
  }
</style>