<template>
  <div class="body-container">
    <BannerSection title="Empeña tu auto y continúa <br class='d-none d-lg-block'/> usándolo" />
    <PerksComponent></PerksComponent>
    <EmpenaTuAutoContinuaUsandoloS1 />
    <div style="background-color: rgba(255, 165, 0, 0.04); padding: 1.5rem 0rem; margin: 1.5rem 0rem;">
      <div class="ibancar-election">
        <p>¿Por qué elegir Ibancar?</p>
      </div>
      <BeneficiosIbancarCarousel />
    </div>
    <SolicitaloBannerS2 />
  </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import BannerSection from '@/components/Landings/BannerSection.vue';
import EmpenaTuAutoContinuaUsandoloS1 from '@/components/Landings/EmpenaTuAutoContinuaUsandolo/EmpenaTuAutoContinuaUsandoloS1.vue';
import PerksComponent from '../components/PerksComponent.vue';
import BeneficiosIbancarCarousel from '../components/PrestamoGarantiaAuto/BeneficiosIbancarCarousel.vue';
import SolicitaloBannerS2 from '@/components/Landings/SolicitaloBannerS2.vue';

export default {
  name: 'EmpenaTuAutoContinuaUsandolo',
  components: {
    BannerSection,
    EmpenaTuAutoContinuaUsandoloS1,
    PerksComponent,
    BeneficiosIbancarCarousel,
    SolicitaloBannerS2
  },
  setup() {
    useMeta(
      {
        title: 'Empeña tu auto sin dejarlo con Ibancar',
        meta: [
          {
            name: 'robots',
            content: 'index'
          },
          {
            name: 'description',
            content: 'Descubre cómo empeñar tu vehículo y seguir disfrutándolo. Obtén liquidez inmediata sin renunciar a tu auto. ¡Conoce las opciones y beneficios de Ibancar!'
          },
          {
            name: 'keywords',
            content: 'Empeña tu auto sin dejarlo, empeñar auto sin dejarlo, empeño de autos sin dejarlo'
          }
        ],
        link: [
          { href: 'https://ibancar.com/prestamos-con-aval-de-coche-sin-cambiar-de-titular', rel: 'alternate', hreflang: 'es-es' },
          { href: 'https://ibancar.mx/prestamo-garantia-auto', rel: 'alternate', hreflang: 'es-MX' }
        ]
      }
    )
  }
}
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.body-container {
  margin-top: 4.5rem;
  min-height: calc(100vh - 115px);
}

.ibancar-election {
  margin: 1rem 2rem 0rem 2rem;

  p {
    color: #004680;
    text-align: center;

    font-family: 'Montserrat-Bold';
    font-size: 1.3rem;
    font-style: normal;
    line-height: normal;
    margin: 1.5rem 0rem;
  }
}

@include media-breakpoint-up(md) {
  .body-container {
    margin-top: 8.5rem;
  }
}
</style>