<template>
  <div class="body-container">
    <BannerSection title="Empeño de autos en Ibancar: <br class='d-none d-lg-block'/>  rápido, seguro y confiable" />
    <PerksComponent></PerksComponent>
    <EmpenoDeAutoEnIbancarS1 />
    <div style="background-color: rgba(255, 165, 0, 0.04); padding: 1.5rem 0rem; margin: 1.5rem 0rem;">
      <div class="ibancar-election">
        <p>¿Por qué elegir Ibancar?</p>
      </div>
      <BeneficiosIbancarCarousel />
    </div>
    <SolicitaloBannerS2 />

  </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import BannerSection from '@/components/Landings/BannerSection.vue';
import EmpenoDeAutoEnIbancarS1 from '../components/Landings/EmpenoDeAutoEnIbancar/EmpenoDeAutoEnIbancarS1.vue';
import PerksComponent from '../components/PerksComponent.vue';
import BeneficiosIbancarCarousel from '../components/PrestamoGarantiaAuto/BeneficiosIbancarCarousel.vue';
import SolicitaloBannerS2 from '@/components/Landings/SolicitaloBannerS2.vue';

export default {
  name: 'EmpenoDeAutoEnIbancar',
  components: {
    BannerSection,
    EmpenoDeAutoEnIbancarS1,
    PerksComponent,
    BeneficiosIbancarCarousel,
    SolicitaloBannerS2
  },
  setup() {
    useMeta(
      {
        title: 'Empeño de autos con Ibancar: la mejor opción en México',
        meta: [
          {
            name: 'robots',
            content: 'index'
          },
          {
            name: 'description',
            content: 'Descubre con Ibancar cómo empeñar tu auto de forma rápida y segura. Obtén el mejor trato y las condiciones más favorables en México. ¡Infórmate ahora!'
          },
          {
            name: 'keywords',
            content: 'Empeño de autos en México'
          }
        ],
        link: [
          { href: 'https://ibancar.com/empenar-coche', rel: 'alternate', hreflang: 'es-es' },
          { href: 'https://ibancar.mx/empeno-de-autos-en-ibancar', rel: 'alternate', hreflang: 'es-MX' }
        ]
      }
    )
  }
}
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';


.body-container {
  margin-top: 4.5rem;
  min-height: calc(100vh - 115px);
}

.ibancar-election {
  margin: 1rem 2rem 0rem 2rem;

  p {
    color: #004680;
    text-align: center;

    /* Mobile 360 px/H2 */
    font-family: 'Montserrat-Bold';
    font-size: 1.3rem;
    font-style: normal;
    line-height: normal;
    margin: 1.5rem 0rem;
  }
}

@include media-breakpoint-up(md) {
  .body-container {
    margin-top: 8.5rem;
  }
}
</style>