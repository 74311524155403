<template>
  <div class="body-container">
    <BannerSection title="Empeño de autos en México: <br class='d-none d-lg-block'/> rápido y confiable" />
    <PerksComponent></PerksComponent>
    <EmpenoDeAutosEnMexicoS1 />
    <div style="background-color: rgba(255, 165, 0, 0.04); padding: 1.5rem 0rem; margin: 1.5rem 0rem;">
      <div class="ibancar-election">
        <p>¿Por qué elegir Ibancar?</p>
      </div>
      <BeneficiosIbancarCarousel />
    </div>
    <SolicitaloBannerS2 />
  </div>
</template>
  
<script>
  import { useMeta } from 'vue-meta';
  import BannerSection from '@/components/Landings/BannerSection.vue';
  import EmpenoDeAutosEnMexicoS1 from '@/components/Landings/EmpenoDeAutosEnMexico/EmpenoDeAutosEnMexicoS1.vue';
import PerksComponent from '../components/PerksComponent.vue';
import BeneficiosIbancarCarousel from '../components/PrestamoGarantiaAuto/BeneficiosIbancarCarousel.vue';
import SolicitaloBannerS2 from '@/components/Landings/SolicitaloBannerS2.vue';
  
  export default {
    name: 'EmpenoDeAutosEnMexico',
    components: {
      BannerSection,
      PerksComponent,
      EmpenoDeAutosEnMexicoS1,
      SolicitaloBannerS2,
      BeneficiosIbancarCarousel
    },
    setup() {
      useMeta(
        {
          title:'Empeño de autos en México',
          meta: [
            {
              name:'robots', 
              content:'index'
            },
            {
              name:'description', 
              content:'El mejor servicio de empeño de autos en México con Ibancar. Obtén préstamos rápidos, seguros y con las mejores tasas. ¡Transforma tu vehículo en efectivo ahora!'
            },
            {
              name:'keywords', 
              content:'Empeño de autos en México, ventajas de empeñar tu auto'
            }
          ],
          link: [
            { href: 'https://ibancar.com/empenar-coche-mas-diez-anos', rel: 'alternate', hreflang: 'es-es' },
            { href: 'https://ibancar.mx/empeno-de-auto-en-mexico', rel: 'alternate', hreflang: 'es-MX' }
          ]
        }
      )
    }
  }
</script>
  
<style scoped lang="scss">

  @import 'bootstrap/scss/_functions.scss'; 
  @import 'bootstrap/scss/_variables.scss';
  @import 'bootstrap/scss/_mixins.scss';

  .body-container{
    margin-top: 4.5rem;

    min-height: calc(100vh - 115px);
  }

  .ibancar-election {
  margin: 1rem 2rem 0rem 2rem;

  p {
    color: #004680;
    text-align: center;

    /* Mobile 360 px/H2 */
    font-family: 'Montserrat-Bold';
    font-size: 1.3rem;
    font-style: normal;
    line-height: normal;
    margin: 1.5rem 0rem;
  }
}

@include media-breakpoint-up(md) {
  .body-container {
    margin-top: 8.5rem;
  }
}
</style>